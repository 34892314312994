<template>
  <table-container :list="list">
    <!-- <el-table-column type="index" label="序号" width="60"></el-table-column> -->
    <el-table-column property="id" label="ID"></el-table-column>
    <el-table-column property="name" label="name"></el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-edit"
          @click="handleEditClick(scope.row)"
          >编辑</el-button
        >
        <el-button
          type="danger"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelClick(scope.row.id)"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    //编辑
    handleEditClick(item) {
      this.$emit("edit", item);
    },
    //删除
    handleDelClick(id) {
      this.$confirm("确认删除？")
        .then(() => {
          this.$emit("del", id);
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
</style>