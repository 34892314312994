<template>
  <ky-dialog
    :title="type === 'add' ? '新增问题件类型' : '编辑问题件类型'"
    :show="show"
    :hide="hide"
  >
    <el-form slot="dialog-content">
      <el-form-item label="类型名称:" label-width="90px">
        <el-input v-model="name" placeholder="请输入类型名称"></el-input>
      </el-form-item>
      <el-form-item label="排序:" label-width="90px">
        <el-input v-model="sort" placeholder="请输入排序"></el-input>
      </el-form-item>
    </el-form>
    <div slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </div>
  </ky-dialog>
</template>
<script>
export default {
  name: "AddType",
  props: {
    show: Boolean,
    item: Object,
    type: String,
  },
  data() {
    return {
      hide: false,
      sort: 1,
      name: "",
      id: "",
    };
  },
  watch: {
    show() {
      if (this.type === "edit") {
        this.name = this.item.name;
        this.id = this.item.id;
      } else {
        this.name = "";
        this.id = "";
      }
    },
  },
  methods: {
    handleCancel() {
      this.hide = !this.hide;
    },
    async handleComfirm() {
      let res;
      if (this.type === "add") {
        res = await this.$store.dispatch("addProblemType", {
          name: this.name,
          sort: this.sort,
        });
      } else {
        res = await this.$store.dispatch("editProblemType", {
          id: this.id,
          name: this.name,
          sort: this.sort,
        });
      }
      this.$tool.comfirmToast(res).then(() => {
        this.hide = !this.hide;
        this.$emit("succ");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>