<template>
  <div class="problem-container">
    <el-row>
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="normal"
        @click="handleAddClick"
        >新增</el-button
      >
    </el-row>
    <list :list="list" @edit="handleEditItem" @del="handleDelItem"></list>
    <add-type
      :show="show"
      :item="item"
      :id="id"
      :type="type"
      @succ="getProblemTypes"
    ></add-type>
  </div>
</template>
<script>
import List from "./components/List";
import AddType from "./components/AddType";
export default {
  name: "ProblemType",
  components: {
    List,
    AddType,
  },
  data() {
    return {
      list: [],
      show: false,
      name: "",
      id: "",
      type: "add",
      item: {},
    };
  },
  created() {
    this.getProblemTypes();
  },
  methods: {
    async getProblemTypes() {
      const res = await this.$store.dispatch("getProblemTypes");
      this.list = res.items;
    },
    //新增类型
    handleAddClick() {
      this.show = !this.show;
      this.type = "add";
    },
    //编辑类型
    handleEditItem(item) {
      this.item = item;
      this.show = !this.show;
      this.type = "edit";
    },
    //删除类型
    async handleDelItem(id) {
      const res = await this.$store.dispatch("delProblemType", { id });
      this.$tool.comfirmToast(res).then(() => {
        this.getProblemTypes();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.problem-container {
  width: 100%;
}
</style> 
